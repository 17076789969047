<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Saída Resíduos / {{ $route.params.id ? 'Editar' : 'Novo' }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">
                        <div v-if="$store.state.user.root" class="col-span-12 md:col-span-12">
                            <label for="empresa" class="block text-sm font-medium">Empresa</label>
                            <select v-model="form.empresa" id="empresa" name="empresa" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                                <option v-for="e in empresas" :key="e._id" :value="e._id">{{e.nome}}</option>
                            </select>
                        </div>
                        <div class="col-span-12 md:col-span-12">
                            <label for="obra" class="block text-sm font-medium">Obra</label>
                            <select v-model="form.obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                                <option v-for="obra in obras" :key="obra._id" :value="obra._id" ><span v-if="$store.state.user.root">{{ obra.empresa.nome }} - </span>{{ obra.nome }}</option>
                            </select>
                        </div> 
                    </div>
                </div>

                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-12">
                            <label for="obra" class="block text-sm font-medium">Resíduo</label>
                            <select v-model="form.nome" id="nome" name="nome" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                                <option v-for="re in residuos" :key="re" :value="re" >{{ re }}</option>
                            </select>
                        </div> 
                      
                        <div class="col-span-12 md:col-span-4">
                            <label for="quantidade" class="block text-sm font-medium">Quantidade m³</label>
                            <input v-model="form.quantidade" type="number" name="quantidade" id="quantidade" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md">
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="data" class="block text-sm font-medium">Data</label>
                            <datepicker v-model="form.data" :language="ptBR" name="data" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"/>
                        </div>
                    </div>
                </div>

                <hr class="mx-5 my-5" />

                <div class="px-5 pb-4">
                    <label class="block text-base font-medium mb-2">Documentos</label>
                    <button type="button" @click="$modal.show('importarModal')" class="bg-green-500 hover:bg-green-600 focus:bg-green-600 focus:shadow-sm focus:ring-4 text-white flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-72 font-semibold justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg> 
                        Adicionar documento 
                    </button>
                </div>

                <div class="px-5 pb-4">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Documento</th>
                            <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Número</th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Validade</th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Alertar</th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Opções</th>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(doc, index) in form.documentos" :key="doc.filename">
                                <td class="px-6 py-4 whitespace-nowrap">
                                    {{doc.originalname}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap">
                                    <input v-model="doc.numero" type="text" name="numero" id="numero" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md">
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    {{doc.tipo}}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <span v-if="doc.validade">
                                        {{doc.validade | moment("DD/MM/YYYY") }}
                                    </span>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-center">
                                    <input type="checkbox" v-model="doc.alertar">
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <a class="mr-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400" v-if="doc.filename" target="_blank"
                                    :href="`${api}/v1/${route}/doc/${doc.filename}?token=${jwt}&mimetype=${doc.mimetype}&original=${doc.originalname}`"
                                    >
                                        Download
                                    </a>

                                    <button @click="excluirDoc(doc,index)" class="mr-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400" type="button">Excluir</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="grid grid-cols-2 grap-6">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
        <modal name="importarModal" :adaptive="true" :height="'auto'">
            <div class="px-4 py-3 overflow-auto">
                <h2 class="text-lg text-gray-800 mb-2 font-bold"> Adicionar Documento</h2>
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-6">
                        <label class="block text-sm font-medium">Validade</label>
                        <datepicker v-model="formdoc.validade" :language="ptBR" name="validade" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"/>
                    </div>
                    <div class="col-span-12">
                        <label for="alertar" class="block text-sm font-medium">
                            <input type="checkbox" v-model="formdoc.alertar" class="rounded-sm" id="alertar">
                            <span class="ml-2">
                                Alertar prazo 
                            </span>
                        </label>
                    </div>
                    <div class="col-span-12">
                        <label class="block text-sm font-medium">Tipo Documento</label>
                        <select v-model="formdoc.tipo" id="tipo" name="tipo" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                            <option value="MTR">MTR</option>
                            <option value="CTR">CTR</option>
                            <option value="Outro">Outro</option>
                        </select>
                    </div>
                    <div class="col-span-12">
                        <label class="block text-sm font-medium">Código Documento</label>
                        <input v-model="formdoc.numero" type="text" name="numero" id="numero" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-12">
                        <vue-dropzone ref="doc" id="doc" :options="dropzoneOptions" v-on:vdropzone-success="updateDoc"></vue-dropzone>
                    </div>
                </div>
                <button @click="addDocForm" type="button" class="inline-flex justify-center py-2 px-6 mt-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                    Adicionar
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale';
import vueDropzone from 'vue2-dropzone';

export default {
    components: {
        Datepicker,
        vueDropzone,
    },
    data() {
        return {
            empresas: [],
            ptBR: ptBR,
            route: 'residuos',
            form: {
                obra: null,
                quantidade: '',
                nome: '',
                data: '',
                documentos: [],
            },
            formdoc: {
                validade: '',
                alertar: false,
                tipo: '',
            },
            obras: [],
            dropzoneOptions: this.$http.getDropzoneConfig(
                `/residuos/upload`,
                'post',
                {
                    maxFiles: 1,
                    addRemoveLinks: true,
                    capture: true,
                    thumbnailHeight: 70, 
                    dictDefaultMessage: 'Clique ou arraste arquivos aqui para UPLOAD',
                    dictRemoveFile: 'Remover'
                }
            ),
        }
    },
    methods: {
        async excluirDoc(doc, index) {
            if (confirm("Tem certeza que quer excluir?")) {
                this.form.documentos.splice(index, 1);
                this.$forceUpdate();
                if(!doc._id) return;
                const req = await this.$http.delete(`/v1/${this.route}/removedoc/${doc._id}`);
                if (req.data.success) {
                    this.$vToastify.success("Removido com sucesso!");
                } else {
                    this.$vToastify.error("Erro ao remover");
                }
            }
        },

        addDocForm() {

            if(!this.formdoc.tipo) return this.$vToastify.error("Selecione um tipo de documento");

            this.form.documentos.push(this.formdoc);
            this.form.mtr = this.formdoc.numero;

            this.$refs.doc.removeFile(this.formdoc.file);
            this.formdoc = {
                validade: '',
                alertar: false,
                tipo: '',
            }

            this.$modal.hide('importarModal');
        },

        updateDoc(file, response) {
           this.formdoc = { ...this.formdoc, ...response, file};
        },

        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back()
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    async beforeMount() {
        
        const reqobras = await this.$http.post('/v1/obras/list');
        this.obras = reqobras.data.data;

        const reqEmp = await this.$http.post("/v1/empresas/list");
        this.empresas = reqEmp.data.data;

        const id = this.$route.params.id;

        if (id) {

            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data.residuo;
            this.form.documentos = req.data.documentos;
        }
    },
}
</script>